import { HookResult } from "hooks/HookResult";
import { useCallback, useContext, useState } from "react";
import StripeContext from "./StripeContext";
import { getLocalCurrency } from "services/Location";
import { Coupon, LocalizationInfo, Price } from "@neurosolutionsgroup/models";
import FirebaseAPI from "@neurosolutionsgroup/api-client";

export interface ValidatePromoCodeResponse {
  type: "discount" | "free";
  couponData?: Coupon;
  metaData?: { [name: string]: string };
}

export interface useStripeSelectors {
  prices: Price[];
  referralCoupon: Coupon | null;
  localization?: LocalizationInfo;
}

export interface useStripeActions {
  activateFreeSubscription: (
    code: string,
    priceId: string
  ) => Promise<ValidatePromoCodeResponse>;
  getPrices: VoidFunction;
  getLocalization: VoidFunction;
  validatePromoCode: (code: string) => Promise<ValidatePromoCodeResponse>;
  getYearlyPrice: (
    currency: string,
    coupon?: Coupon,
    raw?: boolean
  ) => Price | undefined;
}

const newPriceIds = [
  // Staging prices.
  "price_1Mga2WG5XNqJ1izvSjWK3ryX",
  "price_1Mga2CG5XNqJ1izvIi5zNOAx",
  "price_1Mga90G5XNqJ1izvISodK2xC",
  "price_1Mga9GG5XNqJ1izvPtSHKxja",
  "price_1Mga9cG5XNqJ1izvfIkAHKgs",
  "price_1Mga9tG5XNqJ1izv9mC8Q27X",
  // Production prices.
  "price_1PptdnG5XNqJ1izv2cyj9xDP",
  "price_1PptdXG5XNqJ1izveOenxcWI",
  "price_1PptdGG5XNqJ1izvhClIMR50",
  "price_1Pptd1G5XNqJ1izvKqyUAXbz",
  "price_1PptcqG5XNqJ1izv56timrrO",
  "price_1PptUmG5XNqJ1izvtkrVDjAK",
];

const useStripe = (): HookResult<useStripeSelectors, useStripeActions> => {
  const {
    prices,
    setPrices,
    referralCoupon,
    setReferralCoupon,
    localization,
    setLocalization,
  } = useContext(StripeContext);

  const [gotPrices, setGotPrices] = useState(false);
  const [gotLocalization, setGotLocalization] = useState(false);

  const getPrices = async () => {
    if (!gotPrices) {
      FirebaseAPI.Billing.Stripe.getPrices().then((response) => {
        setPrices(response.prices.filter((p) => newPriceIds.includes(p.id)));
        setReferralCoupon(response.coupon ?? null);
        setGotPrices(true);
      });
    }
  };

  const getLocalization = async () => {
    if (!gotLocalization) {
      getLocalCurrency().then((response) => {
        setLocalization(response);
        setGotLocalization(true);
      });
    }
  };

  const validatePromoCode = async (
    code: string
  ): Promise<ValidatePromoCodeResponse> => {
    const promoCode = await FirebaseAPI.Billing.Stripe.validatePromoCode(code);

    if (promoCode.type === "discount" && promoCode.promoData) {
      return {
        type: promoCode.type,
        couponData: {
          id: promoCode.promoData.coupon.id,
          promoId: promoCode.promoData.id,
          name: promoCode.promoData.coupon.name ?? "",
          amount_off: promoCode.promoData.coupon.amount_off,
          currency: promoCode.promoData.coupon.currency,
          percent_off: promoCode.promoData.coupon.percent_off,
        },
        metaData: promoCode.promoData.metaData ?? undefined,
      };
    } else {
      return Promise.reject(new Error("Promo code response not recognised."));
    }
  };

  const activateFreeSubscription = async (
    code: string,
    priceId: string
  ): Promise<ValidatePromoCodeResponse> => {
    const promoCode = await FirebaseAPI.Billing.Stripe.activateFreePromoCode(
      code,
      priceId
    );

    if (promoCode.type === "free") {
      return promoCode;
    } else {
      return Promise.reject(new Error("Error activating account."));
    }
  };

  const getYearlyPrice = useCallback(
    (currency: string, coupon?: Coupon, raw = false): Price | undefined => {
      const yearly = prices.find(
        (p) => p.interval === "year" && p.currency === currency
      );

      if (!yearly) {
        return undefined;
      }

      if (raw) {
        return yearly;
      }

      if (!coupon) {
        return {
          ...yearly,
          unitAmount: yearly.unitAmount,
          unitAmountDecimal: yearly.unitAmountDecimal,
        };
      }

      const yearlyCopy = yearly && { ...yearly };

      if (yearlyCopy && yearlyCopy.unitAmount && coupon && coupon.percent_off) {
        yearlyCopy.unitAmount = Math.round(
          yearlyCopy.unitAmount * ((100 - coupon.percent_off) / 100)
        );
      } else if (
        yearlyCopy &&
        yearlyCopy.unitAmount &&
        coupon &&
        coupon.amount_off &&
        coupon.currency &&
        coupon.currency === currency
      ) {
        yearlyCopy.unitAmount = Math.round(
          yearlyCopy.unitAmount - coupon.amount_off
        );
      }

      return yearlyCopy;
    },
    [prices]
  );

  return {
    selectors: {
      prices,
      referralCoupon,
      localization,
    },
    actions: {
      activateFreeSubscription,
      getPrices,
      getLocalization,
      validatePromoCode,
      getYearlyPrice,
    },
  };
};

export default useStripe;
